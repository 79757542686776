import React from "react";
import { ListItem, OrderedList, UnorderedList, Link } from "@chakra-ui/layout";

import Section from "../ui/common/Section";
import Paragraph from "../ui/common/Paragraph";

const items = [
  {
    title: "",
    text: (
      <Paragraph>
        <i>Effective Date: 03 November 2019</i>
      </Paragraph>
    ),
  },
  {
    title: "Applicability",
    text: (
      <Paragraph>
        This Privacy Notice applies to the microPublication.org website and
        related subdomains (e.g. https://portal.micropublication.org/). This
        Privacy Notice explains our general privacy practices about the
        collection, use, and disclosure of user information collected in
        connection with visits to our websites. Certain microPublication
        websites may have their own privacy notices that also apply, and
        visitors to such sites should review the applicable privacy notice.
      </Paragraph>
    ),
  },
  {
    title: "Changes",
    text: (
      <Paragraph>
        The microPublication project may make changes to its privacy policy and
        this Privacy Notice, consistent with its respect for visitor privacy,
        and those changes will be posted in a revised Privacy Notice that will
        show a new effective date and the date of the superseded notice.
        Visitors should periodically review this notice for updates.
      </Paragraph>
    ),
  },
  {
    title: "Collection of Personally Identifiable Information",

    text: (
      <>
        <Paragraph>
          The term “personally identifiable information” means individually
          identifiable information about an individual collected online by a
          site operator from that individual and maintained by the operator in
          an accessible form, including any of the following:
        </Paragraph>
        <OrderedList>
          <ListItem>Name;</ListItem>
          <ListItem>
            Home or other physical address, including street name and name of a
            city or town;
          </ListItem>
          <ListItem>Email address;</ListItem>
          <ListItem>Telephone number;</ListItem>
          <ListItem>IP address.</ListItem>
        </OrderedList>
        <Paragraph>
          microPublication does not automatically collect personally
          identifiable information about visitors to the microPublication
          websites other than the visitor’s IP address.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Automatically Collected Information",
    text: (
      <>
        <Paragraph>
          Web server logs collect and store certain system-related information
          automatically. This information includes:
        </Paragraph>
        <UnorderedList>
          <ListItem>
            The Internet domain from which you access our websites;
          </ListItem>
          <ListItem>
            The IP address from which you access our websites;
          </ListItem>
          <ListItem>
            The type of browser (e.g., Internet Explorer, Firefox, Safari,
            Chrome) used to access our sites;
          </ListItem>
          <ListItem>
            The operating system (Windows, Unix, Macintosh) used to access our
            sites;
          </ListItem>
          <ListItem>The date and time you access our sites;</ListItem>
          <ListItem>The URLs of the pages you visit; and</ListItem>
          <ListItem>
            If you visited a microPublication website from another website, the
            URL of the referring site.
          </ListItem>
        </UnorderedList>

        <Paragraph>
          This information is used to help us make our sites more useful for
          you. With this data, we learn about the number of visitors to our
          sites and the types of technology our visitors use. The information
          collected may be used in aggregate to inform decisions about website
          content and structure. It may also be used for troubleshooting
          purposes, or to detect and investigate abuse. With the exception of
          abuse investigations, no attempts are made to identify individual
          users or their usage habits.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Information Collected for Tracking and Customization (Cookies)",

    text: (
      <Paragraph>
        microPublication websites use cookies to better serve visitors. A cookie
        is a piece of data that is either maintained only for the duration of a
        particular session visit (session cookie) or is stored on the user’s
        computer to support future visits (persistent cookie). microPublication
        sites use both types of cookies. If you do not wish to have session or
        persistent cookies stored on your machine, you can turn them off in your
        browser. However, some of a site’s features may be affected if cookies
        are disallowed.
      </Paragraph>
    ),
  },
  {
    title: "Google Analytics",

    text: (
      <Paragraph>
        microPublication websites use Google Analytics, which employs persistent
        cookies to help analyze how visitors use the sites. Google will not
        associate your IP address with any other data held by Google. Google
        Analytics uses only first-party cookies for data analysis. This means
        that the cookies are associated with microPublication’s domain. Google
        will use this information for statistical analysis reporting related to
        visitor browsing behavior at these sites, and for compiling reports on
        website activity for website operators. Google may also transfer this
        information to third parties where required to do so by law, or where
        such third parties process the information on Google’s behalf. You may
        refuse the use of cookies by selecting the appropriate settings on your
        browser; however please note that if you do this you may not be able to
        use the full functionality of the website. More information about Google
        Analytics and its use of cookies can be found here.
      </Paragraph>
    ),
  },
  {
    title: "Children",
    text: (
      <Paragraph>
        microPublication websites are not directed to children under 13 years of
        age, do not sell products or services intended for purchase by children,
        and do not knowingly collect or store any personal information, even in
        aggregate, about children under the age of 13. We encourage parents and
        teachers to be involved in children’s Internet explorations. It is
        particularly important for parents to guide their children when they are
        asked to provide personal information online.
      </Paragraph>
    ),
  },
  {
    title: "Collection of Voluntarily Submitted Information",
    text: (
      <>
        <Paragraph>
          Other than the technical information collected automatically about
          your visit (described above), some users of microPublication websites
          may choose to submit new articles, conduct reviews of articles as a
          public service and otherwise voluntarily provide information.
          Information may be requested for the purpose of making information or
          services available to you and to maintain and manage our relationship
          with you, including providing communications and associated services,
          or to better understand and serve your needs. This information is
          generally retained as long as you continue to maintain a relationship
          with us. For example, if you sign up for email communications and
          later unsubscribe, your contact information will be deleted.
          Submitting the requested information is wholly voluntary. However, not
          providing the requested information (or subsequently asking that the
          data be removed) may affect our ability to deliver the service for
          which the information is needed. Providing the requested information
          indicates your consent to the collection, use, and disclosure of this
          information as described in this notice. Information we may actively
          collect could include your email addresses if we communicate with you
          via email, your name, and your comments and questions about the
          websites.
        </Paragraph>

        <Paragraph>
          Any articles that you submit to microPublication will be maintained in
          perpetuity as a permanent entry to the scientific record.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Disclosure of Information",
    text: (
      <>
        <Paragraph>
          Other than sharing your information for the processing purposes
          described above, and with other appropriate personnel and units to
          ensure the quality, functionality, and security of our websites, or to
          manage your relationship with us, we will not disclose personally
          identifiable information about your use of these microPublication
          sites except under the following circumstances:
        </Paragraph>
        <UnorderedList>
          - With your prior written (including email) consent; - When we have
          given you clear notice that we will disclose information that you
          voluntarily provide, and the opportunity to oppose that disclosure; or
          - To appropriate external parties, such as law enforcement agencies,
          in order to investigate and respond to suspected violations of law or
          university policy. Any such disclosures shall comply with all
          applicable laws and university policies.
        </UnorderedList>
      </>
    ),
  },
  {
    title: "Website Security Notice",

    text: (
      <Paragraph>
        {" "}
        Unauthorized attempts to upload or change information on
        microPublication websites are strictly prohibited and may be punishable
        by law, including under the Computer Fraud and Abuse Act of 1986, and
        the National Information Infrastructure Protection Act of 1996.
      </Paragraph>
    ),
  },
  {
    tite: "Comments Sent by Email",

    text: (
      <Paragraph>
        You may choose to provide us with personal information, for example in
        an email containing your comments or questions. We may use this
        information to improve our service to you or to respond to your request.
        There may be times when your message is forwarded to other
        microPublication employees who may be better able to help you.
      </Paragraph>
    ),
  },
  {
    title: "Disclaimer of Endorsement",

    text: (
      <Paragraph>
        Reference on a microPublication site to any specific commercial
        products, process, or service by trade name, trademark, manufacturer, or
        otherwise, does not necessarily constitute or imply its endorsement,
        recommendation, or favoring by microPublicaiton. The views and opinions
        of authors expressed herein do not necessarily state or reflect those of
        microPublication, and shall not be used for advertising or product
        endorsement purposes.
      </Paragraph>
    ),
  },
  {
    title: "Users Located in the European Economic Area",

    text: (
      <Paragraph>
        If you reside within the European Union or the European Economic Area
        (consisting of the European Union, Iceland, Lichtenstein, and Norway),
        please click here for additional information regarding rights and
        protections that apply to you under the General Data Protection
        Regulation.
      </Paragraph>
    ),
  },
  {
    title: "Contact Information",
    text: (
      <Paragraph>
        If you have questions or concerns about this policy, please contact us
        via email at{" "}
        <Link href="mailto:contact@micropublication.org">
          contact@micropublication.org
        </Link>
        .
      </Paragraph>
    ),
  },
];

const PrivacySection = (props) => (
  <Section items={items} title="Privacy Notice" isPage />
);

export default PrivacySection;

import React from "react";

import Layout from "../components/Layout";
import PrivacySection from "../content/PrivacySection";

const Privacy = (props) => {
  return (
    <Layout>
      <PrivacySection />
    </Layout>
  );
};

export default Privacy;
